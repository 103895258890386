import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
let str =localStorage.getItem('tltz_userinfo')
let userInfo=str?JSON.parse(str):{}
export default new Vuex.Store({
  state: {
    userInfo,
  },
  mutations: {
    setUserInfo(state, options) {
      console.log(state, options)
      state.userInfo = options;
    }
  },
  actions: {

  },
  modules: {
  }
})
