<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted(){
    //关闭浏览器窗口的时候清空浏览器缓存在localStorage的数据
    // window.onbeforeunload = function(){

    //   console.log(window.sessionStorage["ISlogin"]);
    // var storage = window.localStorage;
    // storage.clear()
    // }
    window.onload = function () {
      console.log(window.sessionStorage["ISlogin"]);
      if (!window.sessionStorage["ISlogin"]) {
        // 关闭浏览器
         var storage = window.localStorage;
    storage.clear()
      } else {
        // 刷新
      }
    };
    window.onunload = function () {
      window.sessionStorage["ISlogin"] = true;
    };
    window.onbeforeunload = function () {
      window.sessionStorage["ISlogin"] = true;
    };

  }
}
</script>

<style lang="less" >
html,body,#app{
  height: 100%;
  // min-width: 1920px;
  // max-height: 1080px;
}

</style>