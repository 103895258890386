<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <div>当前位置：</div>
      <el-breadcrumb-item
        v-for="item in breadList"
        :to="{ path: item.path }"
        :key="item.name"
        >{{ item.meta.title }}</el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "mybreadcrumb",
  components: {},
  data() {
    return {
      breadList: [],
    };
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    //面包屑
    getBreadcrumb() {
      let matched = this.$route.matched.filter((item) => item.name);
      this.breadList = matched;
    },
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
};
</script>
<style lang='less' >
.breadWrap {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  .arrow_wrap {
    width: 40px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #e9ecf7;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-main {
    line-height: 0 !important;
  }
  .page_sum {
    display: flex;
    margin-left: 5px;
    > li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100px;
      height: 40px;
      color: #666666;
      background: #ffffff;
      //   color: #ffffff;
      //   background: #436cd4;
      border: 1px solid #e9ecf7;
      border-radius: 3px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      margin-right: 10px;
      > p {
        text-align: center;
        width: 80px;
      }
      > i {
        margin-right: 5px;
      }
    }
    > li.active {
      color: #ffffff;
      background: #436cd4;
    }
  }
  .el-icon-arrow-right {
    position: absolute;
    right: 25px;
  }
  .el-icon-error {
    position: absolute;
    right: 0;
    top: 12px;
  }
}
.el-breadcrumb {
  display: flex;
  margin-top: 16px;
}
</style>