//存放element-ui
import {
    Button, Input, Form, FormItem, MessageBox, Container, Aside, Header,
    Menu, MenuItem, MenuItemGroup, Submenu, Breadcrumb, BreadcrumbItem,
    Select, Option, Dialog, Popconfirm, Table, TableColumn, Pagination, DatePicker,
    Checkbox, Upload,Switch, Col, Row, Tree, Radio, RadioGroup, Dropdown, DropdownMenu, DropdownItem, Tabs, TabPane, Footer, Main, Loading
} from 'element-ui'
import Vue from 'vue'
[Button, Input, Form, FormItem, Container, Aside, Header,
    Menu, MenuItem, MenuItemGroup, Submenu, Breadcrumb, BreadcrumbItem,
    Select, Option, Dialog, Popconfirm, Table, TableColumn, Pagination, DatePicker,
    Checkbox, Upload,Switch, Col, Row, Tree, Radio, RadioGroup, Dropdown, DropdownMenu, DropdownItem, Tabs, TabPane, Footer, Main, Loading].forEach(item => {
        Vue.use(item)
    })

Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
